import { useEffect, useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { useGetCommonApi, useGetTeacherQuery } from 'hooks';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useRecoilState, useRecoilValue } from 'recoil';
import { loginUser } from 'recoil/loginUser';

import { postDataBodyQuery } from 'api/getDataBodyQuery';
import { categoryType } from 'assets/constant';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';
import { subjectTeacherState } from 'recoil/subject/subjectTeacherState';
import { teacherSubjectState } from 'recoil/subject/teacherSubjectState';
import { viewSubjectTypeControl } from 'util/subject/subjectUtil';

export function SubjectTeacher() {
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);
  const notStudentRole = user?.role !== 'STUDENT';
  const [subjectId, setSubjectId] = useRecoilState(teacherSubjectState);
  const [optionData, setOptionData] = useState([]);
  const [itemType, setItemType] = useState(null);
  const [subjectTeacherData, setSubjectTeacherState] = useRecoilState(subjectTeacherState);

  const {
    // 교수자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSubjectTitleData,
    teacherSubjectTitleIsLoading,
    teacherSubjectTitleIsError,
  } = useGetTeacherQuery();

  const {
    // 교수자 / 관리자 - [학습현황] 등록되어 있는 게시중인 과목 리스트 정보 API
    // subject 상위 select-box list
    teacherSelectBoxQueryData,
    teacherSelectBoxQueryIsLoading,
    teacherSelectBoxQueryIsError,
  } = useGetRequiredApi();

  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();

  const handleSelectBox = (e) => {
    const findData = teacherSelectBoxQueryData.find((item) => item.course_id === e?.value);
    setSubjectId(findData);
    localStorage.setItem(
      'teacherSubjectSelectData',
      JSON.stringify({
        value: findData.course_id,
        label: findData.course_code,
      })
    );
  };

  const teacherSubjectApis = useQueries({
    queries: [
      {
        // 교수자 - [학습현황] 주차별 정보 API ( 셀렉트 박스 선택시 그것에 해당되는 모듈의 정보 api )
        queryKey: ['teacherSubjectListItem', user, subjectId?.course_id],
        queryFn: () =>
          getDataQuery(`/teach/courses/${subjectId?.course_id}/modules/info?total=${subjectId?.total_student_count}`),
        enabled: !!subjectId && notStudentRole,
      },
      {
        // 교수자 - [학습현황] 과목의 모듈별 완료한 학생 수
        queryKey: ['teacherSubjectCompletedStudent', user, subjectId?.course_id],
        queryFn: () => getDataQuery(`/teach/courses/${subjectId?.course_id}/modules/completed-count`),
        enabled: !!subjectId,
      },
      {
        // 교수자 - [학습현황] 과목을 수강중인 학생들의 아이디 목록
        queryKey: ['teacherSubjectStudentList', user, subjectId?.course_id],
        queryFn: () => getDataQuery(`/teach/courses/${subjectId?.course_id}/students`),
        enabled: !!subjectId,
      },
      {
        // 교수자 - [온] 동영상 과목 출석 정보 API
        queryKey: ['teacher--Video_Data', user, subjectTeacherData?.courseId, subjectTeacherData?.itemId],
        queryFn: () =>
          postDataBodyQuery(
            `/teach/courses/${subjectTeacherData?.courseId}/modules/video/info?module_id=${subjectTeacherData?.moduleId}&item_id=${subjectTeacherData?.itemId}`,
            subjectTeacherData?.user_number_list
          ),
        enabled: !!subjectId && itemType === '동영상',
      },
    ],
  });

  const [teacherSubjectList, teacherSubjectCompletedStudentList, teacherSubjectStudentList, teacher] =
    teacherSubjectApis?.map((query) => query);
  const [teacherSubjectListData, teacherSubjectCompletedStudentListData, teacherSubjectStudentListData, teacherData] =
    teacherSubjectApis?.map((query) => query?.data?.data);

  const { isLoading: teacherSubjectListIsLoading, isError: teacherSubjectListIsError } = teacherSubjectList;
  const { isLoading: teacherSubjectCompletedStudentListIsLoading, isError: teacherSubjectCompletedStudentListIsError } =
    teacherSubjectCompletedStudentList;
  const { isLoading: teacherSubjectStudentListIsLoading, isError: teacherSubjectStudentListIsError } =
    teacherSubjectStudentList;

  const handleSetSubjectDetail = (item) => {
    try {
      const subjectDetailData = {
        courseId: subjectId.course_id,
        moduleId: item.module_id,
        itemId: item.item_id,
        assignment: item.content_id,
        studentLmsUserIds: teacherSubjectStudentListData?.student_id_list,
        course_title: subjectId.course_name,
        title: item.title,
        user_number_list: teacherSubjectStudentListData?.user_number_list,
      };

      setItemType(item.type);
      setSubjectTeacherState(subjectDetailData);
      if (item.type === '동영상') return navigate('detail/video');
      navigate('detail');
      return;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return setOptionData(
      teacherSelectBoxQueryData?.map((item) => ({
        value: item.course_id,
        label: item.course_code,
      }))
    );
  }, [teacherSelectBoxQueryData]);

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      resolve(
        teacherSelectBoxQueryData?.map((item) => ({
          value: item.course_id,
          label: item.course_code,
        }))
      );
    });

  return (
    <>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold'>
                    <i className='bi bi-bank2 me-2 color-primary'></i>
                    {/* 학기 정보 */}
                    {semesterData ? semesterData?.data?.name : '기본학기'}
                  </h3>

                  <div className='d-flex align-items-center w-100 ps-3'>
                    <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                      과목
                    </label>

                    <AsyncSelect
                      className='w-100'
                      defaultInputValue={JSON.parse(localStorage.getItem('teacherSubjectSelectData'))?.label}
                      // defaultValue={subjectId && { value: subjectId?.course_id, label: subjectId?.course_name }}
                      placeholder='과목 선택'
                      isLoading={teacherSelectBoxQueryIsLoading}
                      cacheOptions
                      defaultOptions={optionData}
                      onChange={handleSelectBox}
                      loadOptions={promiseOptions}
                    />
                  </div>
                </div>
              </div>

              {teacherSubjectListIsLoading && <SpinnerLoading />}
              {subjectId && (
                <>
                  {/* 셀렉트 information */}
                  <div className=''>
                    {subjectId && (
                      <section className='card px-3 py-4 d-flex justify-content-between align-items-center flex-row'>
                        <div className='d-flex fw-bold fz-18'>
                          <h3 className='d-flex --Palette-DarkGray-80'>{subjectId?.course_name}</h3>
                        </div>
                        <div className='d-flex fz-14 noto-sans align-items-center'>
                          <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary CHA_LIGHT'>
                            <span className=''>교과코드:</span>
                            <span className='fw-bold'>{subjectId?.course_id}</span>
                          </p>
                        </div>
                      </section>
                    )}

                    {/* 아래 카드 */}
                    <div>
                      <div className='mb-3 d-flex px-3'>
                        <i className='bi bi-people-fill me-3'></i>
                        <p className='fz-16'>총 학생 수: {subjectId?.total_student_count}</p>
                      </div>

                      {/* 주차 아이템 */}
                      {teacherSubjectListData?.module_list?.map((item, index) => {
                        // "주차" 앞부분만 가져오기 위해 module_name 숫자만 추출
                        const weekNumberString = item.module_name?.split('주차')[0].trim();
                        const weekNumber = parseInt(weekNumberString, 10);

                        // weekNumber가 유효한 정수라면 그대로 사용하고, 그렇지 않으면 기존 로직이었던 index + 1 사용
                        const count = isNaN(weekNumber) ? index + 1 : weekNumber;

                        return(
                          <div key={item.module_id} className='rounded-2 overflow-hidden bg-white mb-5'>
                            {/* header */}
                            <div className='p-20 bg-second fw-bold text-white d-flex justify-content-between align-items-center'>
                              <p>
                                <span className='me-1'>{count}</span>주차
                              </p>
                              <p className='fw-bold'>
                                <i className='bi bi-check-circle-fill --cha_yellow me-2'></i>
                                주차 완료 :
                                {teacherSubjectCompletedStudentListIsLoading
                                  ? // TODO 로딩 스피너 나중에 추가
                                    '0'
                                  : teacherSubjectCompletedStudentListData[index]?.completed_student_count}
                              </p>
                            </div>
                            {/* body */}
                            <div className='p-20 pb-2 fz-14'>
                              <Table className=''>
                                <thead className='fw-bold --Palette-DarkGray-80'>
                                  <tr className='border-bottom'>
                                    <th className='text-start p-1 py-3' style={{ width: '17.78%', minWidth: '70px' }}>
                                      카테고리
                                    </th>
                                    <th className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                      제목
                                    </th>

                                    <th className='text-start p-1 py-3' style={{ width: '11.00%' }}></th>
                                  </tr>
                                </thead>
                                <tbody className='--Palette-DarkGray-80'>
                                  {item && item?.module_item_list.length !== 0 ? (
                                    item?.module_item_list?.map((el) => (
                                      <tr key={el.item_id} className='border-bottom'>
                                        <td className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                          {categoryType(el.type)}
                                        </td>
                                        <td className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                          {el.title}
                                        </td>
                                        <td className='text-end p-1 py-3' style={{ width: '11.00%' }}>
                                          <button
                                            className='badge link-badge border fz-14'
                                            onClick={() => {
                                              handleSetSubjectDetail(el);
                                            }}
                                            disabled={teacherSubjectStudentListIsLoading}
                                          >
                                            {viewSubjectTypeControl(el.type) || <span>자세히 보기</span>}
                                          </button>
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td colSpan={3}>
                                        <div className='d-flex justify-content-center'>
                                          <EmptyComponent text='등록된 강의가 없습니다' transform={true} />
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </Table>
                            </div>
                          </div>
                          )
                      })}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
