import { useEffect, useState } from 'react';

import { useQueries } from '@tanstack/react-query';
import { getDataQuery } from 'api';
import { categoryType, setSessionStorage } from 'assets/constant';
import { SpinnerLoading } from 'components/common';
import EmptyComponent from 'components/common/EmptyComponent';
import { useGetCommonApi } from 'hooks';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { loginUser } from 'recoil/loginUser';

import { useGetAdminSubjectQuery } from 'hooks/useGetAdminSubjectQuery';
import { useGetRequiredApi } from 'hooks/useGetRequiredApi';

import { subjectTeacherState } from 'recoil/subject/subjectTeacherState';
import { viewSubjectTypeControl } from 'util/subject/subjectUtil';
import { adminSubjectState } from './adminSubjectState';

export function SubjectAdmin() {
  const [a, seta] = useState(null);
  const [b, setb] = useState(null);
  const [c, setc] = useState('');
  const [d, setd] = useState(null);
  const [e, sete] = useState(null);
  const [f, setf] = useState(null);
  const [g, setg] = useState(null);
  const [h, seth] = useState(null);
  const navigate = useNavigate();
  const user = useRecoilValue(loginUser);
  const setSubjectTeacherState = useSetRecoilState(subjectTeacherState);

  const [subjectId, setSubjectId] = useState(null);
  const [optionData, setOptionData] = useState([]);
  const [subOptionData, setSubOptionData] = useState([]);
  const [adminSelectState, setAdminSelectState] = useRecoilState(adminSubjectState);

  const [adminSubjectList, setAdminSubjectList] = useState([]);
  // 어드민 전체 과목 불러오기 전체 학과 리스트 조회
  const { adminSubjectDepartmentData, adminSubjectDepartmentIsLoading, adminSubjectDepartmentIsError, aab } =
    useGetAdminSubjectQuery(a);

  //   const { teacherSubjectTitleData } = useGetTeacherQuery();
  const { semesterData, semesterIsLoading, semesterIsError } = useGetCommonApi();
  const {
    // 관리자 - 전체 학과 리스트 조회
    adminSelectBoxQueryData,
    adminSelectBoxQueryIsLoading,
    adminSelectBoxQueryIsError,

    // 관리자 - 해당 학과의 과목 리스트
    adminSubSelectBoxQueryData,
    adminSubSelectBoxQueryIsLoading,
    adminSubSelectBoxQueryIsError,
    ab1,
    ab2,
  } = useGetRequiredApi(a);

  const teacherSubjectApis = useQueries({
    queries: [
      {
        // 교수자 - [학습현황] 주차별 정보 API ( 셀렉트 박스 선택시 그것에 해당되는 모듈의 정보 api )
        queryKey: ['adminSubjectListItem', user, b, adminSubSelectBoxQueryData],
        queryFn: () => getDataQuery(`/teach/courses/${b}/modules/info?total=${0}`),
        // enabled: !!subjectId && notStudentRole,
        enabled: !!b,
      },
      {
        // 교수자 - [학습현황] 과목의 모듈별 완료한 학생 수
        queryKey: ['adminSubjectCompletedStudent', user, b],
        queryFn: () => getDataQuery(`/teach/courses/${b}/modules/completed-count`),
        enabled: !!b,
      },
      {
        // 교수자 - [학습현황] 과목을 수강중인 학생들의 아이디 목록
        queryKey: ['adminSubjectStudentList', user, adminSelectState],
        queryFn: () => getDataQuery(`/teach/courses/${b}/students`),
        enabled: !!b,
      },
      {
        // 관리자 - 해당 학과의 과목 리스트
        queryKey: ['adminSubjectLectureList', user, adminSelectState],
        queryFn: () => getDataQuery(`/teach/home/account/${b}/courses`),
        enabled: !!b,
      },
    ],
  });

  // TODO 언젠가 훅으로 빼기
  const [teacherSubjectList, teacherSubjectCompletedStudentList, teacherSubjectStudentList, adminSubjectLecture] =
    teacherSubjectApis?.map((query) => query);

  const [
    teacherSubjectListData,
    teacherSubjectCompletedStudentListData,
    teacherSubjectStudentListData,
    adminSubjectLectureData,
  ] = teacherSubjectApis?.map((query) => query?.data?.data);

  // corseId, moduleId, assignment, studentLmsUserIds가 있을 때만 쿼리 실행

  // 코스 아이디 dz
  // 모듈 아이디 dz

  // console.log('a', teacherSubjectListData);
  // console.log('b', teacherSubjectCompletedStudentListData);
  // console.log('c', teacherSubjectStudentListData.student_id_list);
  // console.log('d', adminSubjectLectureData);
  const { isLoading: teacherSubjectListIsLoading, isError: teacherSubjectListIsError } = teacherSubjectList;
  const { isLoading: teacherSubjectCompletedStudentListIsLoading, isError: teacherSubjectCompletedStudentListIsError } =
    teacherSubjectCompletedStudentList;
  const { isLoading: teacherSubjectStudentListIsLoading, isError: teacherSubjectStudentListIsError } =
    teacherSubjectStudentList;

  const { isLoading: adminSubjectLectureIsLoading, isError: adminSubjectLectureIsError } = adminSubjectLecture;
  const handleSetSubjectDetail = (item) => {
    console.log('item', item);
    try {
      const subjectDetailData = {
        courseId: d,
        moduleId: item.module_id,
        assignment: item.content_id,
        studentLmsUserIds: teacherSubjectStudentListData?.student_id_list,
        course_title: c,
        titleaa: item.title,
        ...adminSelectState,
      };

      console.log('subjectDetailData', subjectDetailData);

      setSubjectTeacherState(subjectDetailData);

      setSessionStorage(JSON.stringify(subjectDetailData), 'subjectDetailData');
      navigate('detail');
    } catch (error) {
      console.error('error', error);
    }
  };

  // 학과 이벤트 핸들러
  const handleSelectIdBox = (e) => {
    if (!e.value) return;
    setAdminSelectState({ ...adminSelectState, lectureId: e.value, lectureTitle: e.label });
  };

  // 과목 이벤트 핸들러
  const handleSubjectSelectBox = (e) => {
    const findData = adminSubSelectBoxQueryData?.find((el) => el.account_course_dto.course_id === e.value);
    setAdminSelectState({
      ...adminSelectState,
      subjectId: e.value,
      total_student_count: findData?.student_count,
      title: findData?.account_course_dto.course_name,
      subjectTitle: e.label,
    });
  };

  // 학과 셀렉트 박스 데이터
  useEffect(() => {
    return setOptionData(
      adminSelectBoxQueryData?.map((item) => ({
        value: item.id,
        label: item.name,
      }))
    );
  }, [adminSelectBoxQueryData]);

  // 과목 셀렉트 박스 데이터
  useEffect(() => {
    return setSubOptionData(
      adminSubSelectBoxQueryData?.map((item) => ({
        value: item.account_course_dto.course_id,
        label: item.account_course_dto.course_name,
      }))
    );
  }, [adminSubSelectBoxQueryData]);

  console.log("teacherSubjectListData?.module_list: ", teacherSubjectListData?.module_list);

  return (
    <>
      <section>
        <div className='row'>
          <div className='col'>
            <div className=''>
              {/* 위쪽 */}
              <div className='card p-20 px-4'>
                {/* 헤더 */}
                <div className='d-flex justify-content-between align-items-center'>
                  <h3 className='d-flex align-items-center white-nowrap fw-bold'>
                    <i className='bi bi-bank2 me-2 color-primary'></i>
                    {/* 학기 정보 */}
                    {semesterData ? semesterData?.data?.name : '기본학기'}
                  </h3>

                  <div className='d-flex'>
                    <label htmlFor='' className='me-2'>
                      학과
                    </label>
                    <select
                      className='py-2 px-2 w-full min-w-300'
                      onChange={(e) => {
                        seta(e.target.value);
                        // console.log(e.target);
                        // setc(e.target.selectedOptions[0].text);
                      }}
                    >
                      <option value=''>선택</option>
                      {ab1?.map((el) => (
                        <option value={el.id}>{el.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className='d-flex'>
                    <label htmlFor='' className='me-2'>
                      과목
                    </label>
                    <select
                      onChange={(e) => {
                        setb(e.target.value);
                        // setd(e.target.selectedOptions[0].text);
                        // setc(
                        //   ab2?.find((el) => el.account_course_dto.course_id === e.target.value).account_course_dto
                        //     .course_name
                        // );
                        const aabbss = ab2.find((el) => {
                          return el.account_course_dto.course_id == e.target.value;
                        });

                        setc(aabbss.account_course_dto.course_name);
                        // console.log(aabbss)
                        // console.log(e.target.name)
                        // setd()
                        const zesseTend = aabbss.account_course_dto.course_id;
                        setd(zesseTend);
                      }}
                      disabled={!a}
                      className='py-2 px-2 w-full min-w-300'
                      name=''
                      id=''
                    >
                      <option value=''>선택</option>
                      {ab2?.map((el) => (
                        <option value={el.account_course_dto.course_id}>{el.account_course_dto.course_name}</option>
                      ))}
                    </select>
                  </div>

                  {/* <div className='d-flex align-items-center w-100 ps-3'>
                    <div className='d-flex align-items-center flex-glow-2'>
                      <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                        학과
                      </label>

                      <AsyncSelect
                        className='w-100'
                        defaultInputValue='과목 선택'
                        placeholder='과목 선택'
                        isLoading={adminSubSelectBoxQueryIsLoading}
                        cacheOptions
                        defaultOptions={optionData}
                        onChange={handleSelectIdBox}
                      />
                    </div>

                    <div className='d-flex align-items-center ms-3 flex-glow-3'>
                      <label htmlFor='subjectSelectTitle' className='white-nowrap me-3 d-none d-sm-inline'>
                        과목
                      </label>
                      <AsyncSelect
                        className='w-100'
                        defaultInputValue='과목 선택'
                        placeholder='과목 선택'
                        isLoading={adminSubSelectBoxQueryIsLoading}
                        cacheOptions
                        defaultOptions={subOptionData}
                        isDisabled={!adminSelectState && !adminSubSelectBoxQueryIsLoading}
                        // onChange={}
                      />
                    </div>
                  </div> */}
                </div>
              </div>

              {teacherSubjectListIsLoading && <SpinnerLoading />}
              {teacherSubjectListData && (
                <>
                  {/* 셀렉트 information */}
                  <div className=''>
                    {teacherSubjectListData && (
                      <section className='card px-3 py-4 d-flex justify-content-between align-items-center flex-row'>
                        <div className='d-flex fw-bold fz-18'>
                          <h3 className='d-flex --Palette-DarkGray-80'>{c}</h3>
                        </div>
                        <div className='d-flex fz-14 noto-sans align-items-center'>
                          <p className='ms-3 fz-12 d-flex align-items-center p-2 rounded text-blue-primary CHA_LIGHT'>
                            <span className=''>교과코드:</span>
                            <span className='fw-bold'>{c}</span>
                          </p>
                        </div>
                      </section>
                    )}

                    {/* 아래 카드 */}
                    <div>
                      <div className='mb-3 d-flex px-3'>
                        <i className='bi bi-people-fill me-3'></i>
                        <p className='fz-16'>총 학생 수: {teacherSubjectListData?.total_student_count}</p>
                      </div>
                      {/* 주차 아이템 */}
                      {teacherSubjectListData?.module_list?.map((item, index) => {
                      // "주차" 앞부분만 가져오기 위해 module_name 숫자만 추출
                      const weekNumberString = item.module_name?.split('주차')[0].trim();
                      const weekNumber = parseInt(weekNumberString, 10);

                      // weekNumber가 유효한 정수라면 그대로 사용하고, 그렇지 않으면 기존 로직이었던 index + 1 사용
                      const count = isNaN(weekNumber) ? index + 1 : weekNumber;
                      console.log("count: ", count);
                        return(
                        <div key={item.position} className='rounded-2 overflow-hidden bg-white mb-5'>
                          {/* header */}
                          <div className='p-20 Blue_primary fw-bold text-white d-flex justify-content-between align-items-center'>
                            <p>
                              <span className='me-1'>{count}</span>주차
                            </p>
                            <p className='fw-bold'>
                              <i className='bi bi-check-circle-fill --cha_yellow me-2'></i>
                              주차 완료 :
                              {teacherSubjectCompletedStudentListIsLoading
                                ? // TODO 로딩 스피너 나중에 추가
                                  '0'
                                : teacherSubjectCompletedStudentListData[index]?.completed_student_count}
                            </p>
                          </div>
                          {/* body */}
                          <div className='p-20 pb-2 fz-14'>
                            <Table className=''>
                              <thead className='fw-bold --Palette-DarkGray-80'>
                                <tr className='border-bottom'>
                                  <th className='text-start p-1 py-3' style={{ width: '17.78%', minWidth: '70px' }}>
                                    카테고리
                                  </th>
                                  <th className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                    제목
                                  </th>

                                  <th className='text-start p-1 py-3' style={{ width: '11.00%' }}></th>
                                </tr>
                              </thead>
                              <tbody className='--Palette-DarkGray-80'>
                                {item && item?.module_item_list?.length !== 0 ? (
                                  item?.module_item_list?.map((el) => (
                                    <tr key={el.item_id} className='border-bottom'>
                                      <td className='text-start p-1 py-3' style={{ width: '17.78%' }}>
                                        {categoryType(el.type)}
                                      </td>
                                      <td className='text-start p-1 py-3' style={{ width: '71.22%' }}>
                                        {el.title}
                                      </td>

                                      <td className='text-end p-1 py-3' style={{ width: '11.00%' }}>
                                        <button
                                          className='badge link-badge border fz-14'
                                          onClick={() => {
                                            sete(el.moduleId);
                                            setf(el.assignment_id);
                                            setg(teacherSubjectStudentListData.student_id_list);
                                            handleSetSubjectDetail(el);
                                          }}
                                          disabled={teacherSubjectStudentListIsLoading}
                                        >
                                          {viewSubjectTypeControl(el.type) || <span>자세히 보기</span>}
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan={3}>
                                      <div className='d-flex justify-content-center'>
                                        <EmptyComponent text='등록된 강의가 없습니다' transform={true} />
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      )})}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
